<template>
  <div v-if="SHOW" id="Pdf_preview">
    <transition name="popup">
      <div v-show="loading || !pdfFile" class="lds-parent">
        <div class="lds-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div @click="reset" @mousedown="reset" class="cancelBtn">
          <i class="fa fa-times"></i>
          Închide (ESC)
        </div>
      </div>
    </transition>
    <PdfViewer :pdfFile="pdfFile" @close-pdf="reset"></PdfViewer>

    <!--     <vue-pdf-app
      v-if="pdfFile && !loading"
      style="height: 100vh; font-size: 24px"
      :style="loading ? 'visibility:hidden;' : ''"
      :pdf="pdfFile"
      :config="config"
      :title="false"
      @after-created="stopLoadingAfter(1500)"
      @pages-rendered="stopLoadingAfter(1)"
    >
      <template #toolbar-right-prepend>
        <button
          @click="reset"
          title="Închide previzualizarea"
          class="toolbarButton presentationMode hiddenLargeView"
          style="font-size: 2.7rem; color: #ec8989"
        >
          <i class="fa fa-times" style="line-height: unset"></i>
        </button>
      </template>
      <template #secondary-toolbar-prepend>
        <button
          @click="reset"
          title="Închide previzualizarea"
          class="secondaryToolbarButton firstPage"
          style="
            display: flex;
            align-content: center;
            line-height: 18px;
            font-size: 2rem;
          "
        >
          <i class="fa fa-times" style="line-height: inherit"></i>
          <span>Close</span>
        </button>
        <div data-v-6a576c1f="" class="horizontalToolbarSeparator"></div>
      </template>
    </vue-pdf-app> -->
  </div>
</template>

<style src="@/assets/pdf_preview.css"></style>
<style>
.vue-pdf-app-icon::before,
.vue-pdf-app-icon::after {
  font-size: 2.1rem !important;
}
.fileInput {
  display: none !important;
}
</style>

<script>
import fileManager from "@/mixins/fileManager.js";
import {
  REFERENCE_GET_PDF,
  PAAP,
  ACQUISITIONS,
  VERBAL_PROCESS,
  ANNOUNCEMENT,
} from "@/api.js";
import { mapGetters, mapMutations } from "vuex";
const apiMethods = {
  REFERENCE_GET_PDF: REFERENCE_GET_PDF,
  PAAP_GET_PDF: PAAP.getPdf,
  ACQUISITION_PDF: ACQUISITIONS.getPdf,
  VERBAL_PROCESS_PDF: VERBAL_PROCESS.getPdf,
  ANNOUNCEMENT_PDF: ANNOUNCEMENT.getPdf,
  ACQUISITION_DOCUMENT_PDF: ACQUISITIONS.getDocument,
};
import PdfViewer from "@/components/PdfViewer.vue";
const getPlugin = () => {
  /*   import("vue-pdf-app/dist/icons/main.css");
  return import("vue-pdf-app"); */
};

export default {
  mixins: [fileManager],
  components: {
    // "vue-pdf-app": getPlugin,
    /*  PdfjsPreview, */
    PdfViewer,
  },

  data() {
    return {
      SHOW: false,
      loading: false,
      pdfFile: "",
      lastClosed: 0,
      config: {
        toolbar: {
          toolbarViewerLeft: { findbar: false },
        },
      },
    };
  },
  computed: {
    ...mapGetters(["getReferenceIdForPreview"]),
  },
  watch: {
    getReferenceIdForPreview: {
      handler(val) {
        this.SHOW = !!val;
      },
      immediate: true,
    },
    SHOW(val) {
      if (val) {
        this.init();
      }
    },

    showAllPages() {
      this.page = this.showAllPages ? null : 1;
    },
  },
  methods: {
    ...mapMutations({ resetData: "setReferenceIdForPreview" }),
    handleDocumentRender(args) {
      this.loading = false;
      this.pageCount = this.$refs.pdfRef.pageCount;
    },

    handleDownload(name) {
      this.$refs.pdfRef.download(name);
    },

    stopLoadingAfter(mm) {
      setTimeout(() => {
        this.loading = false;
        this.$root.$el.classList.remove("__Loading");
      }, mm);
    },
    report() {
      this.$toastr.e("A apărut o greșeală. Anunțați administratorul.");
      this.reset();
    },
    removeDocumentBlur() {
      this.$root.$el.classList.remove("__Loading");
    },
    reset(notRemoveListener) {
      this.SHOW = false;
      this.loading = false;
      this.pdfFile = "";
      this.resetData(false);
      this.overflowManager();
      this.removeDocumentBlur();
      this.lastClosed = new Date().getTime();

      const url = window.location.href;
      if (url.includes("#")) {
        window.location.href = `${url.split("#")[0]}#`;
      }

      if (!notRemoveListener) this.removeListener();
    },
    overflowManager(hide) {
      const styleKey = hide ? "hidden" : "visible";
      document.querySelectorAll("html, body").forEach((el) => {
        el.style.overflow = styleKey;
      });
    },
    async preparePdf(file) {
      if (file?.type?.includes("application/pdf")) {
        const preparingPdf = await this.readAsDataUrl(file);
        if (preparingPdf) {
          this.pdfFile = preparingPdf;
          this.loading = false;
        } else {
          this.report();
        }
      } else {
        this.report();
      }
    },
    init() {
      const data = this.getReferenceIdForPreview;
      const functions = {
        REFERENCE_GET_PDF: () => {
          this.loading = true;
          getPlugin();
          let startedAt = new Date().getTime();
          apiMethods["REFERENCE_GET_PDF"](data.data.id)
            .then((file) => {
              const curr = new Date().getTime();
              if (curr - this.lastClosed < curr - startedAt) {
                this.reset(true);
                return;
              }

              this.preparePdf(file);
            })
            .catch((err) => {
              this.$toastr.e(err);
              this.reset();
            });
        },
        PAAP_GET_PDF: () => {
          this.loading = true;
          getPlugin();
          let startedAt = new Date().getTime();
          apiMethods["PAAP_GET_PDF"](data.data.id)
            .then((file) => {
              const curr = new Date().getTime();
              if (curr - this.lastClosed < curr - startedAt) {
                this.reset(true);
                return;
              }

              this.preparePdf(file);
            })
            .catch((err) => {
              this.$toastr.e(err);
              this.reset();
            });
        },

        ACQUISITION_PDF: () => {
          this.loading = true;
          getPlugin();
          let startedAt = new Date().getTime();
          apiMethods["ACQUISITION_PDF"](data.data.id)
            .then((file) => {
              const curr = new Date().getTime();
              if (curr - this.lastClosed < curr - startedAt) {
                this.reset(true);
                return;
              }

              this.preparePdf(file);
            })
            .catch((err) => {
              this.$toastr.e(err);
              this.reset();
            });
        },

        VERBAL_PROCESS_PDF: () => {
          this.loading = true;
          getPlugin();
          let startedAt = new Date().getTime();
          apiMethods["VERBAL_PROCESS_PDF"](data.data.id)
            .then((file) => {
              const curr = new Date().getTime();
              if (curr - this.lastClosed < curr - startedAt) {
                this.reset(true);
                return;
              }

              this.preparePdf(file);
            })
            .catch((err) => {
              this.$toastr.e(err);
              this.reset();
            });
        },

        ANNOUNCEMENT_PDF: () => {
          this.loading = true;
          getPlugin();
          let startedAt = new Date().getTime();
          apiMethods["ANNOUNCEMENT_PDF"](data.data.id)
            .then((file) => {
              const curr = new Date().getTime();
              if (curr - this.lastClosed < curr - startedAt) {
                this.reset(true);
                return;
              }

              this.preparePdf(file);
            })
            .catch((err) => {
              this.$toastr.e(err);
              this.reset();
            });
        },

        ACQUISITION_DOCUMENT_PDF: () => {
          this.loading = true;
          getPlugin();
          let startedAt = new Date().getTime();
          apiMethods["ACQUISITION_DOCUMENT_PDF"](data.data.id, data.data.name)
            .then((file) => {
              const curr = new Date().getTime();
              if (curr - this.lastClosed < curr - startedAt) {
                this.reset(true);
                return;
              }

              this.preparePdf(file);
            })
            .catch((err) => {
              this.$toastr.e(err);
              this.reset();
            });
        },

        readPdf: () => {
          if (data.data) {
            this.pdfFile = data.data;
          } else {
            this.reset();
          }
        },
      };

      if (data?.file) {
        this.setListener();
        this.overflowManager(true);
        this.preparePdf(data.file);
      } else if (data?.origin && functions[data.origin]) {
        this.setListener();
        this.overflowManager(true);
        functions[data.origin]();
      } else {
        this.reset();
      }
    },
    escapeDetectListener(x) {
      if (x?.key === "Escape") {
        this.reset();
      }
    },
    removeListener() {
      document.removeEventListener("keydown", this.escapeDetectListener);
    },
    setListener() {
      document.addEventListener("keydown", this.escapeDetectListener);
    },
  },
  beforeDestroy() {
    this.removeListener();
  },
};
</script>
